<template>
  <div class="about">
    <div class="about_top" @click="go">
      <van-image
        class="about_top_img"
        fit="cover"
        :src="require('@/assets/img/about_01.jpg')"
      />
      <div class="about_scope">
        <div class="about_scope_box">
          <div>
            <h3>10万+</h3>
            <div>
              <span class="scope_unit">服务全球企业</span>
            </div>
          </div>
          <div class="ellipsis"></div>
          <div>
            <h3>270万+</h3>
            <div>
              <span class="scope_unit">服务雇员人数</span>
            </div>
          </div>
          <div class="ellipsis"></div>
          <div>
            <h3>378座+</h3>
            <div>
              <span class="scope_unit">服务全球城市</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="about_text">
      中智薪税凭借积累多年得行业最佳实践经验，科学
      设计企业薪税管理模式，结合智能技术不断完善线上薪酬、财税服务平台
      ，为企业理供系统化，流程化。合规
      化的薪酬及税务管理，释放内部人力资源管理部门生产力，聚焦核心业务，提升管理水品及员工满意度，深入分析人力成本数据，辅助管理决策，主力企业“智享薪税，智荟人才”
      。
    </div>
    <van-image
      class="advertise_img"
      fit="cover"
      :src="require('@/assets/img/about_02.jpg')"
    />
    <div class="nav_list">
      <div class="nav_box" v-for="item in navList" :key="item.name">
        <van-image class="nav_icon" fit="cover" :src="item.icon" />
        <p>{{ item.name }}</p>
      </div>
    </div>
    <van-image
      class="advertise_bottom_img"
      fit="cover"
      :src="require('@/assets/img/about_03.jpg')"
    />
    <copyrightIp />
  </div>
</template>
<script>
import { Image as VanImage } from "vant";
import copyrightIp from "@/components/copyrightIp";

export default {
  components: {
    [VanImage.name]: VanImage,
    copyrightIp,
  },
  data() {
    return {
      navList: [
        {
          name: "政府关系良好稳定",
          icon: require("@/assets/img/about_icon_01.jpg"),
        },
        {
          name: "服务网络覆盖全国",
          icon: require("@/assets/img/about_icon_02.jpg"),
        },
        {
          name: "安全机制严密可靠",
          icon: require("@/assets/img/about_icon_03.jpg"),
        },
        {
          name: "服务团队专业稳定",
          icon: require("@/assets/img/about_icon_04.jpg"),
        },
        {
          name: "新证法规实时传递",
          icon: require("@/assets/img/about_icon_05.jpg"),
        },
        {
          name: "服务系统便携智能",
          icon: require("@/assets/img/about_icon_06.jpg"),
        },
      ],
    };
  },
  created() {
    localStorage.removeItem("code");
  },
  methods: {
    go() {
      window.open("http://zzxs.xinyanhecheng.com/oauthLogin"); // 新窗口
    },
  },
};
</script>

<style lang="scss" scoped>
.about {
  width: 100%;
  background: #fff;
  .about_top {
    position: relative;
    .about_top_img {
      width: 100%;
    }
    .about_scope {
      width: 100%;
      height: 75px;
      padding: 0 10px;
      position: absolute;
      bottom: -46px;
      left: 0;

      .about_scope_box {
        width: 100%;
        height: 100%;
        background: #fff;
        box-shadow: 0 2px 4px rgb(0 0 0 / 12%), 0 0 6px rgb(0 0 0 / 4%);
        border-radius: 8px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        h3 {
          font-weight: bold;
          font-size: 20px;
          padding-bottom: 5px;
          color: #5198f8;
        }

        .scope_unit {
          font-size: 12px;
        }
        .ellipsis {
          width: 1px;
          height: 35px;
          background: #dcdfe6;
        }
      }
    }
  }
  .about_text {
    padding: 65px 10px 20px;
    line-height: 22px;
    // font-weight: bold;
    font-size: 14px;
    text-indent: 2em;
  }
  .advertise_img {
    width: 100%;
  }
  .nav_list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: repeat(2, 119px);
    background: #fff;
    text-align: center;
    // font-weight: bold;
    font-size: 14px;
    padding: 10px 0 25px;
    .nav_icon {
      width: 97px;
      height: 77px;
    }
  }
  .advertise_bottom_img {
    width: 100%;
  }
}
</style>
